<template>
  <div class="cpt-MMC_Gimbal_S1">
    <div v-interact class="hd">
      <div class="title">抛投</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">预设重量</div>
          <div class="input-box">
            <div class="text-box">{{ height }} 克</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态</div>
          <div class="input-box">
            <div class="text-box">正常</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">智能模式</div>
          <div class="input-box">
            <el-switch
              v-model="lntelligent_mode_status"
              size="mini"
              @change="handle_change_lntelligent_mode_ctrl"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">执行动作</div>
          <div class="input-box">
            <el-switch
              v-model="motor_weight_status"
              size="mini"
              @change="handle_change_motor_weight"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">重量（g）</div>
          <div class="input-box">
            <el-input v-model="pre_height" size="mini" placeholder="请输入重量" />
            <el-button size="mini" type="primary"
@click="height = pre_height">预设</el-button>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <img src="@/assets/images/observe/006.svg" class="dib w200 h200" alt="" />
        <!-- <div class="title-box">
          <div class="title">俯仰操作</div>
        </div>
        <div class="rocker" ref="rocker">
          <div class="shangUp" @click="fangxiang('up')"></div>
          <div class="xaiUp" @click="fangxiang('down')"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_S1 } from '../utils';
export default {
  data() {
    return {
      timer: null,
      height: 300,
      pre_height: 300,
      lntelligent_mode_status: false,
      motor_weight_status: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  methods: {
    handle_change_lntelligent_mode_ctrl(value) {
      const buffer = MMC_Gimbal_S1.lntelligent_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_motor_weight(value) {
      const buffer = MMC_Gimbal_S1.motor_weight(value);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      this.$emit('directive', buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case 'up':
          buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(1);
          this.commit_directive(buffer);
          break;
        case 'right':
          // _this.gimbal_yaw_ctrl(1);
          break;
        case 'left':
          // _this.gimbal_yaw_ctrl(-1);
          break;
        case 'down':
          buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(-1);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //  // buffer = gimbal_pitch_ctrl(0, this.control_speed);
      //     // this.commit_directive(buffer);
      // }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case 'up':
              buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(1);
              this.commit_directive(buffer);
              break;
            case 'right':
              // _this.gimbal_yaw_ctrl(1);
              break;
            case 'left':
              // _this.gimbal_yaw_ctrl(-1);
              break;
            case 'down':
              buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(-1);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          // buffer = gimbal_pitch_ctrl(0, this.control_speed);
          // this.commit_directive(buffer);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_S1 {
  position: absolute;
  top: 0px;
  right: 0;
  /* background: center url("~@/assets/images/observe/fckernel/mount/01_bg.png")
    no-repeat; */
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  width: 500px;
  height: 290px;
  box-sizing: border-box;
  padding: 10px 20px;
  .hd {
    .title {
      font-size: 18px;
      color: #08c2d1;
      font-weight: bold;
      line-height: 2;
    }
  }
  .bd {
    display: flex;
    justify-content: space-between;
    .form-wrap {
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .text-box {
            color: #dce9ff;
          }
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 154px;
        height: 154px;
        background: -0.5px 3.5px url('~@/assets/images/accident/operation.png') no-repeat;
        background-size: 100% calc(100% - 3.5px);
        position: relative;
        .shangUp {
          position: absolute;
          left: 34%;
          top: 0px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 34%;
          bottom: 0px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>
